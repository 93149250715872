import { MainLayout } from "../components/Layouts/MainLayout";
import white_arrow from "../assets/images/icons/white-left-arrow.svg";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import plugins from "suneditor/src/plugins";
import { Container as ContainerDrag, Draggable } from "react-smooth-dnd";
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useDeleteUnit,
  useGetCourseInfo,
  useGetStandard,
  useGetUnitInfo,
  useSaveUnit,
} from "../services/Query";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useRef, useState } from "react";
import minus_icon from "../assets/images/icons/red_minus.svg";
// import htmlToDraft from 'html-to-draftjs';
// import { ContentState, EditorState } from 'draft-js';
import plus_icon from "../assets/images/icons/circle_plus_icon.svg";
import ReactLoading from "react-loading";
import { ConfirmRemoveModal } from "../components/modals/ConfirmRemoveModal";
import SunEditor from "suneditor-react";
import { ConfirmModal } from "../components/modals/ConfirmModal.server";
import { ConfirmModalStandard } from "../components/modals/ConfirmModalStandard";
import { generateString, loopValues } from "../models/defaults";
type UnitFrm = {
  id?: string;
  course_id: string;
  order: number;
  name: string;
  documentIds: string[]
  learningObjects?: string
  keyIdeas: string;
  keyIdea: string[];
  description: string;
  companion: string;
  pages: string;
};
const backend_opensaltUrl = process.env.REACT_APP_OPENSALT;
const backend_Url = process.env.REACT_APP_BACKEND_URL;
 
const EditUnit = () => {
  let { course_id, unit_id } = useParams();
  const [searchParams] = useSearchParams();
  const orderUnitLast = searchParams.get("o");

  const navigate = useNavigate();
  const { data: unitInfo, isLoading } = useGetUnitInfo(unit_id);
  const { data: courseInfo, isLoading: isLoadingCourse } =
    useGetCourseInfo(course_id);
  const { mutate: onSaveUnit, isLoading: isSaving } = useSaveUnit();
  const [keyIdeas, setKeyIdeas] = useState<string[]>(unitInfo?.keyIdea || [""]);
  const [learningObjectiveLessons, setLearningObjectiveLessons] = useState<any>([])
  const { mutate: deleteItem, isLoading: isDeleting } = useDeleteUnit();
  const [removeShow, setRemoveShow] = useState(false);
  const [removeKeyShow, setRemoveKeyShow] = useState(false);
  const refKeyIdeasTab = useRef<number>(0);
  const refKeyStandardTab = useRef<string>("")
  const { data: standards } = useGetStandard();
  const [chooseDocument, setChooseDocument] = useState<string[]>([]);
  const [itemCFAssociation, setItemCFAssociation] = useState<any>([])
  const [isOpenDocument, setIsOpenDocument] = useState<boolean>(false)
  const [expanded, setExpanded] = useState([]);
  const refDocumentIds = useRef<string[]>([])
  const [listNoteDocuments, setListNoteDocuments] = useState<any>([])
  const [itemObjectDocuments, setItemObjectDocuments] = useState<any[]>([]);
  const [showMsg, setShowMsg] = useState("");
  const refCurrentDocumentId = useRef<string>("")

  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = useForm<UnitFrm>({ mode: "onChange" });

  useEffect(() => {
    if (unitInfo) {
      reset({
        id: unitInfo.id,
        name: unitInfo.name,
        course_id: unitInfo.unit_Course_Id || "",
        keyIdeas: unitInfo.keyIdeas || "",
        keyIdea: unitInfo.keyIdea || [],
        description: unitInfo.description,
        companion: unitInfo.companion,
        pages: unitInfo.pages,
        order: unitInfo.order,
        // keyIdeas : unitInfo.keyIdeas,
      });
      if (!!unitInfo.documentIds) {
        setChooseDocument(unitInfo.documentIds)
        setListNoteDocuments(JSON.parse(unitInfo.learningObjects || "[]"))
      }
    } else {
      reset({
        name: "",
        keyIdeas: "",
        keyIdea: [],
        course_id: course_id,
        description: "",
        companion: "",
        pages: "",
        order: parseInt(orderUnitLast || "1"),
      });
    }

    // get objective lesson.
    if (unitInfo?._Lessons && unitInfo?._Lessons?.length > 0) {
        var objectiveArr = unitInfo?._Lessons.map((it: any) => JSON.parse(it.lesson_Standards)).flat(1).filter(it => it != null);
        setLearningObjectiveLessons(objectiveArr);
    }

    trigger();
  }, [unitInfo]);

  useEffect(() => {
    if (!!courseInfo?.documentIds && courseInfo.documentIds?.length > 0) {
      const promises = courseInfo?.documentIds.map((item: any) => {
        return fetch(`${backend_Url}/api/Standard/get_standard_url?jsonUrl=${backend_opensaltUrl}/ims/case/v1p0/CFItems/${item}`)
          .then(response=> {
          return response.json()
        });
      });


      Promise.all(promises).then(results => {
        const itemDocuments = results.map((res: any) => {
          if (!!res.CFDocumentURI?.identifier && !refDocumentIds.current.includes(res.CFDocumentURI?.identifier)) {
            refDocumentIds.current = [...refDocumentIds.current, res.CFDocumentURI.identifier];
          }
          return {
            parentId: res.CFDocumentURI?.identifier,
            humanCodingScheme: res.humanCodingScheme,
            fullStatement: res.abbreviatedStatement ?? res.fullStatement,
            value: res.identifier,
            label: <span className="fancytree-title"><span className="item-humanCodingScheme">{res.humanCodingScheme}</span> {res.abbreviatedStatement ?? res.fullStatement} </span>,
          }
        });
        setItemObjectDocuments(itemDocuments)
      })
    }
  }, [courseInfo])


  useEffect(() => {
      if (!!courseInfo && itemObjectDocuments.length == courseInfo.documentIds?.length && itemObjectDocuments.length > 0) {
        const promises = refDocumentIds.current.map((item: any) => {
          return fetch(`${backend_Url}/api/Standard/get_standard_url?jsonUrl=${backend_opensaltUrl}/ims/case/v1p0/CFPackages/${item}`)
            .then(response=> {
            return response.json()
          });
        });
  
        Promise.all(promises).then(results => {
          const CFData = results.map((res: any) => {
            refCurrentDocumentId.current = res.CFDocument.identifier;
            return {
              DocumentId: res.CFDocument.identifier,
              CFAssociations: res.CFAssociations,
              CFItems: res.CFItems,
            }
          });
          const data = itemObjectDocuments.map((record: any) => {
            const item = CFData.find((it: any) => it.DocumentId == record.parentId);
            if (!!item) {
              return {
                ...record,
                children: onLoadAssociations(item.CFAssociations, item.CFItems, record)
              }
            }
            return record;
          })
          setItemCFAssociation(data);
        })
      }
  }, [itemObjectDocuments])

  useEffect(() => {
    const nextfield = document.querySelector(
      `input[name=key-ideas-${refKeyIdeasTab.current}]`
    );

    if (nextfield !== null) {
      (nextfield as HTMLElement).focus();
    }
  }, [keyIdeas])

  useEffect(() => {
    const nextfield = document.querySelector(
      `input[name=stand-input-${refKeyStandardTab.current}]`
    );
    if (nextfield !== null) {
      (nextfield as HTMLElement).focus();
      refKeyStandardTab.current = ""
    }
  }, [listNoteDocuments])
  
  const sameLevelDocuments = useMemo(() => {
    if (!!itemCFAssociation && itemCFAssociation.length > 0) {
      return loopValues(itemCFAssociation);
    }
    return [];
  }, [itemCFAssociation])

  const titleFrameWork = useMemo(() => {
    if (!!standards && !!refCurrentDocumentId.current) {
      return standards.CFDocuments.find((it: any) => it.identifier == refCurrentDocumentId.current)?.creator;
    }
    return ""
  }, [standards, refCurrentDocumentId.current])

  const onLoadAssociations = (CFAssociations: any, CFItems: any,  objNew: any) => {
      const documentIds = CFAssociations.filter((it: any) => it.destinationNodeURI.identifier == objNew.value).map((it: any) => it.originNodeURI.identifier);
      const documentCFItems = CFItems.filter((item: any) => documentIds.includes(item.identifier));
      const documentTrees = documentCFItems.map((it: any) => {
        let newObject: any = {
          value: it.identifier,
          humanCodingScheme: it.humanCodingScheme,
          fullStatement: it.abbreviatedStatement ?? it.fullStatement,
          label: <span className="fancytree-title"><span className="item-humanCodingScheme">{it.humanCodingScheme}</span> {it.abbreviatedStatement ?? it.fullStatement} </span>,
          children:  getLoopDocumentLevel(it, CFAssociations, CFItems),
        };
      
        return newObject;
      })
      
      return documentTrees;
   }

  const getLoopDocumentLevel = (document: any, listDocumentItems: any, CFItems: any) => {
    const documentIds = listDocumentItems.filter((it: any) => it.destinationNodeURI.identifier == document.identifier).map((it: any) => it.originNodeURI.identifier);
    const documentCFItems = CFItems.filter((item: any) => documentIds.includes(item.identifier));
    const documentTrees = documentCFItems.map((it: any) => {
      let newObject: any = {
        value: it.identifier,
        humanCodingScheme: it.humanCodingScheme,
        fullStatement: it.abbreviatedStatement ?? it.fullStatement,
        label: <span className="fancytree-title"><span className="item-humanCodingScheme">{it.humanCodingScheme}</span> {it.abbreviatedStatement ?? it.fullStatement} </span>,
      };

      const childrenData = getLoopDocumentLevel(it, listDocumentItems, CFItems);
      if (!!childrenData && childrenData.length > 0) {
        newObject.children = childrenData;
      }
      return newObject;
    })
    return documentTrees;
  }

  const onSave = () => {
    const values = getValues();
    values.keyIdea = keyIdeas;
    if (listNoteDocuments.length > 0) {
      const afterNote = listNoteDocuments.map((item: any) => {
        item.children = item.children.filter((it: any) => it.title != "")
        return item;
      });
      const afterNoteIds = afterNote.map((it: any) => it.identifier);
      values.documentIds = chooseDocument.filter((it) => afterNoteIds.includes(it));

      values.learningObjects = JSON.stringify(afterNote)
    }
    onSaveUnit(JSON.stringify(values), {
      onSuccess: (res) => {
        navigate(-1);
      },
      onError: () => {},
    });
  };
  const removeId = useRef<number | undefined>(undefined);
  const onConfirmDelete = () => {
    const msg = `Are you sure to delete the <b>${unitInfo?.name}</b>?`;
    setShowMsg(msg);
    setRemoveShow(true);
  };

  const onDropItem = (e: any) => {
    const currentPos = e.removedIndex;
    const newPos = e.addedIndex;
    const result = [...keyIdeas];
    let itemToAdd = result[currentPos];
    if (currentPos !== null) {
      itemToAdd = result.splice(currentPos, 1)[0];
    }
  
    if (newPos !== null) {
      result.splice(newPos, 0, itemToAdd);
    }

    setKeyIdeas(result);
  };

  const addRowStandard = (record: any) => {
    const itemStand: any = listNoteDocuments.find((it: any) => it.identifier == record.identifier);
    if (itemStand) {
      let newObject = {
        id: generateString(10),
        title: ""
      };
      itemStand.children = [...itemStand.children, newObject];
      refKeyStandardTab.current = newObject.id;
    }
    const index: any = listNoteDocuments.findIndex((it: any) => it.identifier == record.identifier);
    if (index != -1) {
      listNoteDocuments[index] = itemStand;
      setListNoteDocuments([...listNoteDocuments])
    }
  }

  const onAddStandardForDocument = () => {
    if (chooseDocument.length > 0) {
      var itemCheckedIds: any = [];
      if (listNoteDocuments.length > 0) {
        itemCheckedIds = listNoteDocuments.map((it:any) => it?.identifier);
      }
      const dataChoose = chooseDocument.filter((it: string) => !itemCheckedIds.includes(it)).map((it) => {
        const document = sameLevelDocuments.find((item: any) => item.value == it);
          // check exits

        return {
          parentId: document.parentId,
          identifier: it,
          title: document.label,
          humanCodingScheme: document.humanCodingScheme,
          fullStatement: document.fullStatement,
          children: [
            {
              id: generateString(10),
              title: ""
            }
          ]
        }
      })
      const data = [...listNoteDocuments, ...dataChoose].filter(it => chooseDocument.includes(it?.identifier));
      setListNoteDocuments(data)
    }
    setIsOpenDocument(false);
  }

  const onchangeNoteDocument = (identifier: string, id: any, value: string) => {
    const itemStand: any = listNoteDocuments.find((it: any) => it.identifier == identifier);
    if (itemStand) {
      var itemNote: any = itemStand.children.find((it: any) => it.id == id);
      itemNote.title = value;
      const indexChild = itemStand.children.findIndex((it: any) => it.id == id);
      itemStand.children[indexChild] = itemNote;
    }
    const index: any = listNoteDocuments.findIndex((it: any) => it.identifier == identifier);
    if (index != -1) {
      listNoteDocuments[index] = itemStand;
      setListNoteDocuments([...listNoteDocuments])
    }
  }

  const onRemoveNoteDocument = (identifier: string, id: any) => {
    const itemStand: any = listNoteDocuments.find((it: any) => it.identifier == identifier);
    if (itemStand) {
      itemStand.children = itemStand.children.filter((it: any) => it.id != id);
    }
    const index: any = listNoteDocuments.findIndex((it: any) => it.identifier == identifier);
    if (index != -1) {
      listNoteDocuments[index] = itemStand;
      setListNoteDocuments([...listNoteDocuments])
    }
  }

  const onRemoveDocument = (identifier: string) => {
    const itemStand: any = listNoteDocuments.filter((it: any) => it.identifier !== identifier);
    setListNoteDocuments([...itemStand])
    setChooseDocument([...itemStand.map((item: any) => item.identifier)])
  }

  if (isLoading || isLoadingCourse) {
    return (
      <MainLayout>
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"bubbles"}
            color={"#00B1E1"}
            height={30}
            width={375}
          />
        </div>
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <div className="top_blue_action_bar  py-2 d-flex justify-content-center fnt_robo">
        <button
          className="back_btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={white_arrow} alt="" /> back
        </button>
        <h4 className="title">{unitInfo ? "save unit" : "add unit"}</h4>
      </div>
      <div className="edit_box mx-auto py-3">
        <div className="row">
          <div className="col-6">
            <Form.Group className="mb-3 fnt_robo">
              <Form.Label>
                <b>unit name *</b>
                <span className="d-block">the title of your unit</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
              />
            </Form.Group>
          </div>
        </div>
        
        <div className="row">
          <div className="col">
            <Form.Group className="mb-3 fnt_robo">
              <Form.Label>
                <b>key ideas * </b>
                <span className="d-block">
                  input at least one key idea addressed in the unit
                </span>
              </Form.Label>
                <ContainerDrag 
                     onDrop={(e) => onDropItem(e)}
                     render={(ref) => {
                      return (
                        <div className="key-ideas" ref={ref}>
                          {keyIdeas.map((it, idx) => {
                            return (
                              <Draggable 
                                key={idx}
                                render={() => (
                                  <div className="d-flex mb-2" key={idx}>
                                  <div
                                    className="d-flex align-items-center me-1"
                                    style={{ width: "25px" }}
                                  >
                                    <i className="fas fa-grip-vertical"></i>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    className="mt-0"
                                    name={`key-ideas-${idx}`}
                                    style={{ width: "80%", boxShadow: "none" }}
                                    key={idx}
                                    value={it}
                                    onChange={(e) => {
                                      refKeyIdeasTab.current = idx;
                                      const newItem = [...keyIdeas];
                                      newItem[idx] = e.target.value;
                                      setKeyIdeas(newItem);
                                    }}
                                    onKeyDown ={(e) => {
                                      if (e.key == "Enter") {
                                        const nwItems = [...keyIdeas];
                                        nwItems.push("");
                                        setKeyIdeas(nwItems);
                                        refKeyIdeasTab.current = idx + 1;
                                      }
                                    }}
                                  />
                                  <div style={{ width: "25px" }}>
                                    {idx > 0 && (
                                      <img
                                        src={minus_icon}
                                        alt=""
                                        className="ms-2"
                                        onClick={() => {
                                          removeId.current = idx;
                                          setRemoveKeyShow(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                                )}
                              />
                            )
                          })}
                        </div>
                      )
                    }}
                />
             
            </Form.Group>
            <div className="d-flex justify-content-center">
              <img
                src={plus_icon}
                alt=""
                className="cursor"
                onClick={() => {
                  const nwItems = [...keyIdeas];
                  nwItems.push("");
                  setKeyIdeas(nwItems);
                  // setRemoveKeyShow(true);
                }}
              />
            </div>
          </div>
        </div>

        {
          !!itemCFAssociation && (
            <div className="row">
              <div className="col-12 standard-unit">
                <Form.Group className="mb-3 fnt_robo">
                  <Form.Label>
                    <div className="d-flex justify-content-center flex-column">
                      <b>standards and learning objectives</b> 
                      <span className="d-block">add standards from your course framework and break them down into learning objectives you can use in your lessons</span>
                      <Form.Group className="input-objectives mt-3 mb-3 fnt_robo d-flex">
                          <div className="text-objectives">
                                <Form.Control
                                  type="text"
                                  placeholder="select unit standards"
                                  disabled={true}
                                />
                              <img
                                src={plus_icon}
                                alt=""
                                className="cursor"
                                onClick={() => {
                                  setIsOpenDocument(true)
                                }}
                              />
                          </div>
                      </Form.Group>
                  </div>
                  </Form.Label>
                </Form.Group>
              </div>
              <div className="row">
                <div className="col-12">
                  {
                    !!listNoteDocuments && listNoteDocuments.length > 0 && listNoteDocuments.map((it: any, index: number) => (
                      <>
                      {
                        index == 0 && (
                          <b style={{ marginBottom: "12px", display: "block" }}>
                            <i className="fal fa-clipboard-list-check fa-lg me-1"></i> <span>{titleFrameWork}</span>
                          </b>
                        )
                      }
                      <div className="content-standard">
                          <div className="standard-break d-flex">
                            <img
                                src={minus_icon}
                                alt=""
                                className="ms-2"
                                onClick={() => {
                                  onRemoveDocument(it?.identifier)
                                }}
                              />
                                <ul className="d-flex align-items-center flex-column">
                                  <li>
                                    <span style={{ fontWeight: 700 }}>{it.humanCodingScheme}</span> {it.fullStatement}
                                  </li> 
                                </ul>
                          </div>
                          <p className="description-objective">add learning objectives</p>
                          {
                            !!it.children && it.children.length > 0 && it.children.map((record: any) => (
                              <div className="d-flex mb-2 ml-2">
                                  <div
                                    className="d-flex align-items-center me-1"
                                    style={{ width: "25px" }}
                                  >
                                    <i style={{ fontSize: "10px", marginLeft: "8px" }} className="fas fa-circle"></i>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    className="mt-0"
                                    name={`stand-input-${record.id}`}
                                    style={{ width: "90%", boxShadow: "none" }}
                                    value={record.title}
                                    key={record.id}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      onchangeNoteDocument(it?.identifier, record.id, value)
                                    }}
                                    onKeyDown ={(e) => {
                                      if (e.key == "Enter") {
                                        e.preventDefault()
                                        addRowStandard(it)
                                      }
                                    }}
                                  />
                                  {
                                    !!it.children && it.children.length > 1 && (
                                      <div style={{ width: "25px", cursor: "point" }}>
                                          <img
                                            src={minus_icon}
                                            alt=""
                                            className="ms-2"
                                            onClick={() => onRemoveNoteDocument(it?.identifier, record.id)}
                                          />
                                      </div>
                                    )
                                  }
                                 
                              </div>
                            ))
                          }

                          {
                            learningObjectiveLessons.length > 0 && learningObjectiveLessons.filter((a: any) => a.identifier == it.identifier && it.children.find((t: any) => t.title.includes(a.title)) == null).map((record: any) => (
                              <div className="d-flex mb-2 ml-2">
                                  <div
                                    className="d-flex align-items-center me-1"
                                    style={{ width: "25px" }}
                                  >
                                    <i style={{ fontSize: "10px", marginLeft: "8px" }} className="fas fa-circle"></i>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    className="mt-0"
                                    name={`stand-input-${record.id}`}
                                    style={{ width: "90%", boxShadow: "none" }}
                                    disabled
                                    value={record.title}
                                    key={record.id}
                                  />
                              </div>
                            ))
                          }
                          
                          <div className="d-flex justify-content-center">
                            <img
                              src={plus_icon}
                              alt=""
                              className="cursor"
                              onClick={() => addRowStandard(it)}
                            />
                          </div>
                      </div>
                      </>
                    ))
                  }
                  
                </div>
              </div>
             
            </div>
          )
        }

        <div className="row">
          <div className="col">
            <Form.Label className="fw-bold fnt_robo">
              companion printed title(s)
            </Form.Label>
            <div className="row fnt_robo">
              <div className="col-7">
                <InputGroup className="mb-3">
                  <Form.Select {...register("companion")}>
                    <option></option>
                    {courseInfo?.companions?.map((it, idx) => (
                      <option value={it.title} key={idx}>
                        {it.title}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </div>
              <div className="col">
                <InputGroup className="mb-3">
                  <InputGroup.Text>pages or page range</InputGroup.Text>
                  <Form.Control type="text" {...register("pages")} />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        <div>
          <b className=" fnt_robo">unit description</b>
          <SunEditor
            name="preparation_val"
            defaultValue={unitInfo?.description || ""}
            setOptions={{
              height: 'auto', 
              minHeight: '250px',
              plugins: plugins,
              buttonList: [
                ["undo", "redo"],
                ["formatBlock"],
                ["blockquote"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                ],
                ["table", "list", "link", "image", "video"],
              ],
            }}
            onChange={(content) => {
              setValue("description", content);
              // onChange(content, nIndex);
            }}
            // onChange={(content: string) => {
            //   // if (nValue != content) onChange(content, nIndex);
            // }}
            // onBlur={(event: any, content: string) => {
            //   if (nValue != content) onChange(content, nIndex);
            // }}
          />
          {/* <SunEditor
            height={"250"}
            name="preparation_val"
            defaultValue={unitInfo?.description || ""}
            onChange={(content) => {
              setValue("description", content);
              // onChange(content, nIndex);
            }}
          /> */}
          {/* <Editor
            toolbar={{
              options: [
                'inline',
                'blockType',
                'fontSize',
                'fontFamily',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'remove',
                'history',
              ],
            }}
            defaultEditorState={contentState}
            wrapperClassName="demo-wrapper draft_wrapper"
            onChange={(ev) => {
              const strVal = draftToHtml(ev);
              setValue('description', strVal);
              // onChange(strVal, nIndex);
            }}
            editorClassName="demo-editor"
          /> */}
        </div>
        <div className="row mt-3 fnt_robo">
          <div className="col-4">
            <InputGroup>
              <InputGroup.Text className="fw-bold">unit number *</InputGroup.Text>
              <Form.Control
                type="number"
                {...register("order", { required: true })}
                disabled
              />
            </InputGroup>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          {unitInfo && (
            <button
              className="remove_btn me-3"
              disabled={!isValid}
              onClick={() => onConfirmDelete()}
            >
              delete unit
            </button>
          )}
          <button
            className="save_btn"
            disabled={!isValid}
            onClick={() => onSave()}
          >
            {isSaving && (
              <Spinner animation="border" size={"sm"} className="me-1" />
            )}
            {unitInfo ? "save unit" : "add unit"}
          </button>
        </div>
      </div>
      {removeShow && (
        <ConfirmRemoveModal
          show={removeShow}
          isDeleting={isDeleting}
          onDiscard={() => {
            if (unitInfo?.id) {
              deleteItem(unitInfo?.id, {
                onSuccess: () => {
                  setRemoveShow(false);
                  navigate(`/dashboard/course/${unitInfo._Course.course_Id}`);
                },
              });
            }
          }}
          onOk={() => {
            setRemoveShow(false);
          }}
          message={showMsg}
        />
      )}
      <ConfirmModal
        show={removeKeyShow}
        content="Are you sure you want to delete the key idea?"
        leftTitle="no"
        rightTitle="yes"
        onDiscard={() => {
          const nwItems = [...keyIdeas];
          nwItems.splice(removeId.current || 0, 1);
          setKeyIdeas(nwItems);
          setRemoveKeyShow(false);
        }}
        onOk={() => {
          setRemoveKeyShow(false);
        }}
      />
      {
        isOpenDocument && (
          <ConfirmModalStandard
            show={isOpenDocument}
            leftTitle="cancel"
            rightTitle="done"
            itemCFAssociation={itemCFAssociation}
            checked={chooseDocument}
            setChecked={setChooseDocument}
            expanded={expanded}
            setExpanded={setExpanded}
            onDiscard={() => onAddStandardForDocument()}
            onOk={() => {
              setIsOpenDocument(false);
            }}
          />
        )
      }
      <ConfirmModalStandard
        show={isOpenDocument}
        leftTitle="cancel"
        rightTitle="done"
        itemCFAssociation={itemCFAssociation}
        checked={chooseDocument}
        setChecked={setChooseDocument}
        expanded={expanded}
        setExpanded={setExpanded}
        titleFramework={titleFrameWork}
        onDiscard={() => onAddStandardForDocument()}
        onOk={() => {
          setIsOpenDocument(false);
        }}
      />
    </MainLayout>
  );
};
export default EditUnit;
